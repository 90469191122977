<template>
    <div>
        <!-- Page Title -->
        <section id="page-title">

			<div class="container clearfix">
				<h1>Services</h1>
				<span>We provide Amazing Solutions</span>
				<ol class="breadcrumb">
					<li class="breadcrumb-item"><router-link to="/">Home</router-link></li>
					<li class="breadcrumb-item active" aria-current="page">Services</li>
				</ol>
			</div>

		</section>

        <!-- Content -->
        <section id="content">
			<div class="content-wrap">
				<div class="container clearfix">

					<div class="row col-mb-50">

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/customer-success"><i class="icon-headset i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Customer Support</h3>
									<p>eFlex has built successful customer support teams for partners by focusing on an essential character trait: empathy. Empower your support team today with our empathetic and customer-centric support agents.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/technical-support"><i class="icon-laptop i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Technical Support</h3>
									<p>eFlex has highly skilled computer science and IT professionals to support a wide range of technical tasks, which gives local tech professionals more time to code, build products, and upgrade features. Empower your tech teams today.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/customer-experience"><i class="icon-line-thumbs-up i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Customer Experience</h3>
									<p>eFlex has business-savvy professionals that understand the importance of customer success. The experience of your customers is essential to reducing churn. Empower your customer experience teams today.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/real-estate"><i class="icon-house-user i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Real Estate</h3>
									<p>eFlex has built powerful real estate teams to help close billions of dollars in transactions for real estate brokers across the United States. Empower your real estate company today.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/marketing-assistance"><i class="icon-megaphone i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Marketing Assistance</h3>
									<p>Whether email marketing, social media management, or SEO-related tasks, eFlex has built teams that have saved partners thousands of hours in productivity empowering local teams to focus on strategy and execution.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="sales-development"><i class="icon-dollar i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Sales Development</h3>
									<p>It takes a very focused and motivated sales team to find the right opportunities. eFlex has built strong SDR teams that prospect, clean data, and execute outreach campaigns to drive volume into the sales funnel. Empower your sales teams today.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/billing-and-accounting"><i class="icon-file-invoice-dollar i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Billing and Accounting Assistance</h3>
									<p>eFlex is a customer-centric and success-driven company. A key component of achieving these characteristics is unwavering trustworthiness. Empower your finance teams with eFlex.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/HR"><i class="icon-line-head i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>HR Assistance</h3>
									<p>Growing companies get buried in onboarding, offboarding, and loads of HR busywork. eFlex has created HR teams for partners to take the payload off of local team members. Empower your HR operations today.</p>
								</div>
							</div>
						</div>

						<div class="col-sm-6 col-lg-4">
							<div class="feature-box fbox-rounded fbox-effect">
								<div class="fbox-icon">
									<router-link to="/operational-assistance"><i class="icon-wrench i-alt"></i></router-link>
								</div>
								<div class="fbox-content">
									<h3>Operational Assistance</h3>
									<p>eFlex's success can be attributed to its ability to build processes. A primary task of operations is to create workflows that allow for growth and scalability. eFlex has empowered numerous companies by executing solid business processes.</p>
								</div>
							</div>
						</div>
					</div>

				</div>
				<!-- <div class="row bottommargin-lg align-items-stretch" style="padding: 140px 0 10px;">

					<div class="col-lg-4 dark col-padding overflow-hidden" style="background-color: #1abc9c;">
						<div>
							<h3 class="text-uppercase" style="font-weight: 600;">Why choose Us</h3>
							<p style="line-height: 1.8;">Transform, agency working families thinkers who make change happen communities. Developing nations legal aid public sector our ambitions future aid The Elders economic security Rosa.</p>
							<a href="#" class="button button-border button-light button-rounded button-reveal text-right text-uppercase m-0"><i class="icon-angle-right"></i><span>Read More</span></a>
							<i class="icon-bulb bgicon"></i>
						</div>
					</div>

					<div class="col-lg-4 dark col-padding overflow-hidden" style="background-color: #34495e;">
						<div>
							<h3 class="text-uppercase" style="font-weight: 600;">Our Mission</h3>
							<p style="line-height: 1.8;">Frontline respond, visionary collaborative cities advancement overcome injustice, UNHCR public-private partnerships cause. Giving, country educate rights-based approach; leverage disrupt solution.</p>
							<a href="#" class="button button-border button-light button-rounded text-uppercase m-0">Read More</a>
							<i class="icon-cog bgicon"></i>
						</div>
					</div>

					<div class="col-lg-4 dark col-padding overflow-hidden" style="background-color: #e74c3c;">
						<div>
							<h3 class="text-uppercase" style="font-weight: 600;">What you get</h3>
							<p style="line-height: 1.8;">Sustainability involvement fundraising campaign connect carbon rights, collaborative cities convener truth. Synthesize change lives treatment fluctuation participatory monitoring underprivileged equal.</p>
							<a href="#" class="button button-border button-light button-rounded text-uppercase m-0">Read More</a>
							<i class="icon-thumbs-up bgicon"></i>
						</div>
					</div>

				</div> -->
			</div>
		</section>

		<Contact/>
    </div>
</template>

<script>
import Contact from '@/components/ContactUs.vue'
export default {
	name: 'ContactUsPage',
	components: {
		Contact
	}
}
</script>

<style scoped>

</style>